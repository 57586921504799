(function() {
  'use strict';
  /*all about choose floor*/
    function setFloorInfo(dataFlatsStr, floorNum) {
        if (dataFlatsStr !== undefined && (typeof dataFlatsStr === 'undefined' ? 'undefined' : typeof(dataFlatsStr)) === 'object') {
            $('.j-floor-num').each(function () {
                var floor = $(this);
                floor.text(floorNum);
            });
            $('.j-floor-link').each(function () {
                var floor = $(this);
                floor.text(floorNum);
            });
            for (var key in dataFlatsStr) {
                if (key !== 'free') {
                    if (key !== '0') {
                        $('.j-floor-info').append('<div class="el-desc-list_item">' + key + ' rooms: from ' + dataFlatsStr[key] + '</div>');
                    } else {
                        $('.j-floor-info').append('<div class="el-desc-list_item">Studios: from ' + dataFlatsStr[key] + '</div>');
                    }
                } else {
                    $('.j-floor-info').prepend('<div class="el-desc-list_item _title">' + dataFlatsStr[key] + ' apartments for sale</div>');
                }
            }
        } else {
            console.log('wrong data in floor ' + elem.data('num'));
        }
    }
    $('.j-floor').click(function () {
       $("#house").removeClass('is-active');
       $("#content-house").removeClass('is-active');
       console.log($("#floor"))
       $("#floor").addClass('is-active');
        $("#content-floor").addClass('is-active');
    });
    if ($('*').is('.j-floor-choose')) {
        var firstFloor = $('.j-floor.active');
        var dataFlatsStrFirst = firstFloor.data('flats');
        var floorNumFirst = firstFloor.data('num');
        var classTextArr = firstFloor.attr('class').split(' ');

        $('.j-floor-info').html('');
        $('.j-floor-num').each(function () {
            var floor = $(this);
            floor.text(floorNumFirst);
        });
        $('.j-floor-link').each(function () {
            var floor = $(this);
            floor.text(floorNumFirst);
        });
        for (var key in dataFlatsStrFirst) {
            if (key !== 'free') {
                if (key !== '0') {
                    $('.j-floor-info').append('<div class="el-desc-list_item">' + key + ' rooms: from ' + dataFlatsStrFirst[key] + '</div>');
                } else {
                    $('.j-floor-info').append('<div class="el-desc-list_item">Studio: from ' + dataFlatsStrFirst[key] + 'рублей</div>');
                }
            } else {
                $('.j-floor-info').prepend('<div class="el-desc-list_item _title">' + dataFlatsStrFirst[key] + ' apartments to sale</div>');
            }
        }
    }

    $('.j-floor').hover(function () {
        $('.j-floor').each(function () {
            var obj = $(this);
            var classTextArr = obj.attr('class').split(' ');
            for (var i = 0; i < classTextArr.length; i++) {
                if (classTextArr[i] === 'active') {
                    classTextArr.splice(i, i);
                }
                if (classTextArr[i] === 'first-active') {
                    classTextArr.splice(i, i);
                }
            }
            var classTextNew = classTextArr.join(' ');
            obj.attr('class', classTextNew);
        });

        $('.j-floor-info').html('');
        var elem = $(this);
        var classText = elem.attr('class');
        var dataFlatsStr = elem.data('flats');
        var floorNum = elem.data('num');
        var classActive = classText + ' ' + 'active';
        elem.attr('class', classActive);
        $('.j-floor-change-input').val(floorNum);
        setFloorInfo(dataFlatsStr, floorNum);

    }, function () {
        var elem = $(this);
    });

    //Click choose floor button
    $('.j-choose-top').click(function (e) {
        e.preventDefault();
        e.stopPropagation();
        var num = parseInt($('.j-floor-link').text());
        if (num + 1 <= 31) {
            $('.j-floor-info').html('');
            $('.j-floor-link').text(num + 1);
            $('.j-floor').each(function () {
                var elem = $(this);
                var floorNum = elem.data('num');
                var classText = elem.attr('class');
                var classTextArr = classText.split(' ');
                if (floorNum === num + 1) {
                    var dataFlatsStr = elem.data('flats');
                    var floorNum = elem.data('num');
                    var classActive = classText + ' ' + 'active';
                    elem.attr('class', classActive);
                    setFloorInfo(dataFlatsStr, floorNum);
                    $('.j-floor-change-input').val(num + 1);
                } else {
                    for (var i = 0; i < classTextArr.length; i++) {
                        if (classTextArr[i] === 'active') {
                            classTextArr.splice(i, i);
                        }
                    }
                    var classTextNew = classTextArr.join(' ');
                    elem.attr('class', classTextNew);
                }
            });
        } else {
            return false;
        }
    });
    $('.j-choose-bot').click(function (e) {
        e.preventDefault();
        e.stopPropagation();
        var num = parseInt($('.j-floor-link').text());
        if (num - 1 >= 2) {
            $('.j-floor-info').html('');
            $('.j-floor-link').text(num - 1);
            $('.j-floor').each(function () {
                var elem = $(this);
                var floorNum = elem.data('num');
                var classText = elem.attr('class');
                var classTextArr = classText.split(' ');
                if (floorNum === num - 1) {
                    var dataFlatsStr = elem.data('flats');
                    var floorNum = elem.data('num');
                    var classActive = classText + ' ' + 'active';
                    elem.attr('class', classActive);
                    setFloorInfo(dataFlatsStr, floorNum);
                    $('.j-floor-change-input').val(num - 1);
                } else {
                    for (var i = 0; i < classTextArr.length; i++) {
                        if (classTextArr[i] === 'active') {
                            classTextArr.splice(i, i);
                        }
                    }
                    var classTextNew = classTextArr.join(' ');
                    elem.attr('class', classTextNew);
                }
            });
        } else {
            return false;
        }
    });
    $('.j-flat').hover(function () {
        var elem = $(this);
        var dataFlat = elem.data('flat');
        if ((typeof dataFlat === 'undefined' ? 'undefined' : typeof(dataFlat)) === 'object') {
            var price = dataFlat.price;
            var area = dataFlat.area;
            var priceM = dataFlat.priceM;
            var num = dataFlat.num;
            var title = dataFlat.title;
            $('.j-area').text(area);
            $('.j-price').text(price);
            $('.j-price-m').text(priceM);
            $('.j-num').text(title + ' ' + num);
            elem.css({"opacity":1});
        } else {
            console.log('wron dataflat');
        }
    }, function () {
        var elem = $(this);
        elem.css({"opacity":0.8});
    });
    $('.j-flat').click(function(){
        $("#floor").removeClass('is-active');
        $("#content-floor").removeClass('is-active');
        $("#flat").addClass('is-active');
        $("#content-flat").addClass('is-active');
    });
/* END all about choose floor*/
    //rangeSlider

    $(".j-ran").each(function(){
        var obj = $(this);
        var inpFrom = obj.closest('.b-range-item').find('.from');
        var inpTo = obj.closest('.b-range-item').find('.to');
        var from = obj.data('from');
        inpFrom.val(from);
        var to = obj.data('to');
        inpTo.val(to);
        obj.ionRangeSlider({
            type: "double",
            onChange: function(data) {
                inpFrom.val(data.from);
                inpTo.val(data.to);
                var form = obj.closest('form');
                var data = form.serialize();
                //getAjaxData(data);
            }
        });
        var sliderInp = obj.data("ionRangeSlider");

        var stateFrom = obj.closest('.b-range-item').find('.irs-from');
        stateFrom.text(sliderInp.from);
        var stateTo = obj.closest('.b-range-item').find('.irs-to');
        var posFrom = stateFrom.offset();
        var posTo = stateTo.offset();
        //inpFrom.offset(posFrom);
        //inpTo.offset(posTo);
        inpFrom.focus(function(){
            $(this).css({'z-index':'101'});
        });
        inpFrom.blur(function(){
            var curFrom = $(this).val();
            sliderInp.update({from:curFrom});
            $(this).css({'z-index':'10'});
        });
        inpTo.focus(function(){
            $(this).css({'z-index':'101'});
        });
        inpTo.blur(function(){
            var curTo = $(this).val();
            sliderInp.update({to:curTo});
            $(this).css({'z-index':'10'});
        });

    });
    // Cache selectors
    var lastId,
        topMenu = $("#top-menu"),
        topMenuHeight = topMenu.outerHeight()+15,
        // All list items
        menuItems = topMenu.find("a"),
        // Anchors corresponding to menu items
        scrollItems = menuItems.map(function(){
            var item = $($(this).attr("href"));
            if (item.length) { return item; }
        });

// Bind click handler to menu items
// so we can get a fancy scroll animation
    menuItems.click(function(e){
        var href = $(this).attr("href"),
            offsetTop = href === "#" ? 0 : $(href).offset().top;
        $('html, body').stop().animate({
            scrollTop: offsetTop
        }, 300);
        e.preventDefault();
    });

// Bind to scroll
    var videoDesc = $('.j-change-slides');

    $(window).scroll(function() {
        // Get container scroll position
        var fromTop = $(this).scrollTop();
        if (videoDesc !== null) {
            var videoDescPos = videoDesc.offset();
            console.log(videoDescPos);
            console.log((fromTop - 300), videoDescPos.top);
        if(fromTop > videoDescPos.top - 500){

                console.log('we hear');
                var items = videoDesc.find('.j-change-item');
                var length = items.length;
                var changeTl = new TimelineMax({});
                [...items].forEach((item, i) => {
                    changeTl.to(item, 1.5, {delay: 0.5, opacity: 1, rotationY: 0, transformOrigin: '0% 0%'});
                });
            }
        }
        // Get id of current scroll item
        var cur = scrollItems.map(function(){
            if ($(this).offset().top < fromTop + 200){

                return this;
            }

        });
        // Get the id of the current element
        cur = cur[cur.length-1];
        var id = cur && cur.length ? cur[0].id : "";
        if (lastId !== id) {
            lastId = id;
            menuItems.each(function(){
                var obj = $(this);
                var href = obj.attr('href');
                if(href == '#'+id){
                    obj.addClass('nav-item__current');
                }
                else {
                    obj.removeClass('nav-item__current');
                }

            });

        }
    });

    $('body').click(function(){
        $('.j-sucsess').removeClass('active');
    });
    $('form').submit(function(e){
        e.preventDefault();
        var form = $(this);
        var data = form.serialize();
        $.ajax({
            url:'/mail.php',
            action:"post",
            data:data,
            success:function(r){
                if(r == 'ok'){
                    $('.j-sucsess').addClass('active');
                }
            }
        })
    });
    $('.j-show-desc').click(function () {
        var obj = $(this);
        var openedContent = obj.closest('.u-main-wr').find('.b-desc');
        if(obj.hasClass('active')){
            obj.removeClass('active');
            openedContent.removeClass('active');
        }
        else {
            obj.addClass('active');
            openedContent.addClass('active');
        }


    });
    $('.j-play-video').click(function(){
        var obj = $(this);
        var video = document.getElementById('video');
        console.log(video);
        if(obj.hasClass('active')){
            video.pause();
            obj.removeClass('active');
        }
        else {
            video.play();
            obj.addClass('active');
        }
    });
    class Slider {
        constructor(options) {
            let defaultOpt = {
                container:"j-slider",
                dots:true,
                slide:".j-slider--item",
                turn:true,
                arrNav:true,
                arrows:"j-slider--arr",
                dotsClass:"j-slider--dots",
                slideTime:1.8,
                arrLeft:`<div class="slider-arrs--arr arr-left" data-direction="prev">
                 <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                     <use xlink:href="assets/img/sprite.svg#arrleft" x="0" y="0"/>
                 </svg>
               </div>`,
                arrRight:`<div class="slider-arrs--arr arr-right" data-direction="next">
                 <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                     <use xlink:href="assets/img/sprite.svg#arrright" x="0" y="0"/>
                 </svg>
               </div>`
            };
            let setup = Object.assign({},defaultOpt,options);
            this.container = setup.container;
            this.slideClass = setup.slide;
            this.slides = this.container.querySelectorAll(setup.slide);
            this.slidesLength = this.slides.length;
            this.arrows = setup.arrows;
            this.arrNav = setup.arrNav;
            this.dotsClass = setup.dotsClass;
            this.arrRight = setup.arrRight;
            this.arrLeft =  setup.arrLeft;
            this.dotLine = setup.dots;
            this.arrNav = setup.arrNav;
            this.slideTime = setup.slideTime;

        }
        disableControls(){

            let dots = this.container.querySelectorAll('.'+this.dotsClass+' li');
            let arrs = this.container.querySelectorAll('.'+this.arrows + ' div');
            console.log(arrs);
            if(arrs.length>0){
                [...arrs].forEach((arr)=>{
                    arr.classList.add('is-disabled');
            });
            }
            if(dots.length>0){
                [...dots].forEach((dot)=>{
                    dot.classList.add('is-disabled');
            });
            }
        }
        enableControls(){
            let dots = this.container.querySelectorAll('.'+this.dotsClass+' li');
            let arrs = this.container.querySelectorAll('.'+this.arrows + ' div');
            if(arrs.length>0){
                [...arrs].forEach((arr)=>{
                    arr.classList.remove('is-disabled');
            });
            }
            if(dots.length>0){
                [...dots].forEach((dot)=>{
                    dot.classList.remove('is-disabled');
            });
            }
        }
        slideFromTop(slideId){
            this.disableControls();
            let tlDot = new TimelineLite();
            let currSlide = this.container.querySelector('.current-slide');
            let nextSlide = this.container.querySelector('[data-id="'+slideId+'"');
            tlDot.to(nextSlide,0,{y:"-200%", zIndex:"99", opacity:"1"})
                .to(currSlide,0,{zIndex:"80"})
                .to(nextSlide,1.5,{y:"0%"})
                .to(currSlide,0,{zIndex:"1", opacity:"0"})
                .call(()=>{
                console.log(currSlide);
            currSlide.classList.remove('current-slide');
            nextSlide.classList.add('current-slide');
            this.enableControls();
        });
        }
        createDotNav(){
            let dots = [...this.slides].reduce((acc, item)=>{
                let id =  item.dataset.id;
            let src = item.dataset.small;
            let li = `<li data-id="${id}" style="background:url('${src}')no-repeat; background-size:cover; background-position:50% 50%;">
                     
                    </li>`;
            acc.push(li);
            return acc;
        },[]);
            const dotList = document.createElement('ul');
            dotList.className = this.dotsClass;
            dotList.innerHTML = dots.join('');
            this.container.appendChild(dotList);
            let firstDot = this.container.querySelector('.'+this.dotsClass+' li:first-child');
            firstDot.classList.add('current-dot');
        }
        createArrNav(){
            let arrHolder = document.createElement('div');
            arrHolder.className = this.arrows;
            arrHolder.innerHTML = this.arrLeft + this.arrRight;
            this.container.appendChild(arrHolder);
        }
        slideToRight(slide, slidesLength, dots){
            this.disableControls();
            let tlRight = new TimelineLite();
            let curId = Number(slide.dataset.id);
            let next = curId+1;
            if(next === slidesLength){
                next = 0;
            }
            let nextSlide = this.container.querySelector('[data-id="'+next+'"]');
            console.log(nextSlide);
            tlRight.to(slide, this.slideTime,{x:"150%"})
                .to(nextSlide,0,{opacity:"1"},  "-"+this.slideTime)
                .to(nextSlide,0,{zIndex:"99"})
                .to(slide,0, {zIndex:"1", opacity:"0"})
                .to(slide,0, {x:"0%"})
                .call(()=> {
                slide.classList.remove('current-slide');
            nextSlide.classList.add('current-slide');
            this.enableControls();
        });
            if(dots){
                this.changeActiveDot('sl_'+next);
            }
        }
        slideToLeft(slide, slidesLength, dots){
            this.disableControls();
            let tlLeft = new TimelineLite();
            let curId = Number(slide.dataset.id);
            let next = curId-1;

            if(next === -1){
                next = slidesLength - 1;
            }
            let nextSlide = this.container.querySelector('[data-id="'+next+'"]');
            tlLeft.to(slide, this.slideTime,{x:"-200%"})
                .to(nextSlide,0,{opacity:"1"}, "-"+this.slideTime)
                .to(nextSlide,0,{zIndex:"99"})
                .to(slide,0, {zIndex:"1", opacity:"0"})
                .to(slide,0, {x:"0%"})
                .call(()=> {
                slide.classList.remove('current-slide');
            nextSlide.classList.add('current-slide');
            this.enableControls();
        });
            if(dots){
                this.changeActiveDot('sl_'+next);
            }
        }
        changeActiveDot(dotId){
            let dots = this.container.querySelectorAll('.'+this.dotsClass+' li');
            [...dots].forEach((dot)=>{
                let id = dot.dataset.id;
            if(id===dotId){
                dot.classList.add('current-dot');
            }
            else {
                dot.classList.remove('current-dot');
            }
        });
        }
        init(){
            [...this.slides].forEach((slide, i)=>{
                slide.setAttribute('data-id', i);
        });
            let dots = false;
            let slidesLength = this.slides.length;
            if(this.dotLine){
                this.createDotNav();
                dots = true;
                let dotsNav = this.container.querySelectorAll('.'+this.dotsClass+' li');
                [...dotsNav].forEach((dot)=>{
                    dot.addEventListener('click',(e)=>{
                    let id = dot.dataset.id;
                let dotClass  = dot.classList.contains('current-dot');
                let diasbled = dot.classList.contains('is-disabled');
                if(dotClass || diasbled){
                    return;
                }
                else {
                    this.slideFromTop(id,slidesLength);
                    this.changeActiveDot(id);
                }
            })
            });
            }
            if(this.arrNav){
                this.createArrNav();
                let arrowsCont = this.container.querySelector('.'+this.arrows);
                let arrows = arrowsCont.querySelectorAll('div');
                [...arrows].forEach((arr)=>{
                    arr.addEventListener('click', ()=>{
                    let currentSlide = this.container.querySelector('.current-slide');
                let direction = arr.dataset.direction;
                let diasbled = arr.classList.contains('is-disabled');
                if(diasbled){
                    return;
                }
                if(direction === 'next'){
                    this.slideToRight(currentSlide, slidesLength, dots);
                }
                else if(direction === 'prev'){
                    this.slideToLeft(currentSlide, slidesLength, dots);
                }
            });
            });
            }
        }
    }
    class Tabs {
        constructor (conteiner, index){
            this.conteiner = conteiner;
            this.tabsList =  conteiner.querySelector('.j-tabs-list');
            this.contentList  = this.conteiner.querySelector('.j-content-list');
            this.tabItems = this.tabsList.querySelectorAll('.j-tab');
            this.contentItems = this.contentList.querySelectorAll('.j-content-item');
            this.index = index;
        }
        setAttributes(){
            let tabItems = this.tabItems,
                tabItemsLength = tabItems.length,
                contentItems = this.contentItems,
                contentItemsLength = contentItems.length,
                attributes = {
                    'role': 'tabpanel',
                    'aria-hidden': 'true',
                    'aria-labelledby': 'tab-link-'
                };
            for(let i = 0; i < tabItemsLength; i++){
                tabItems[i].setAttribute('role', 'presentation');
                if(i==0){
                    tabItems[i].classList.add('is-active');
                }
            }
            for(let i = 0; i < contentItemsLength; i++){
                let contentItem = contentItems[i];
                attributes['aria-labelledby'] = 'tab-link-' + i + this.index;
                if(i==0){
                    contentItem.classList.add('is-active');
                    attributes['aria-hidden'] = 'false';
                }
                for (let key in attributes) {
                    contentItem.setAttribute(key, attributes[key]);
                }
            }
        }

        addClickListener(){
            let tabItems = this.tabItems,
                tabItemsLength = tabItems.length,
                contentItems = this.contentItems,
                contentItemsLength = contentItems.length;
            for(let i = 0; i < tabItemsLength; i++){
                let tabItem = tabItems[i];
                tabItem.addEventListener('click',(e)=>{
                    e.preventDefault();
                    let tab = e.currentTarget;
                    for(let j = 0; j < tabItemsLength; j++){
                        let tabItem = tabItems[j];
                        if(j==i){
                            tabItem.classList.add('is-active');
                        }
                        else {
                            tabItem.classList.remove('is-active');
                        }
                    }
                    for(let j = 0; j < contentItemsLength; j++){
                        let contentItem = contentItems[j];
                        if(j==i){
                            contentItem.classList.add('is-active');
                        }
                        else {
                            contentItem.classList.remove('is-active');
                        }
                    }
                });

            }
        }
        init(){
            this.setAttributes();
            this.addClickListener();
        }
    }
    var tabWidget = document.querySelectorAll('.j-tabs-widget');
    for(let i = 0; i < tabWidget.length; i++){
        let tabs = new Tabs(tabWidget[i], i);
        tabs.init();
    };
    let sliders = document.querySelectorAll('.j-slider');
    if(sliders.length > 0){
        [...sliders].forEach((slider)=>{
            let options = {
                container:slider,
                dots:false,
                slideTime:1.5
            }
            let sliderDef = new Slider(options);
        sliderDef.init();
    })
    }

})();